import React from 'react';
import styled from 'styled-components';
import Text from 'components/common/Text';

const CityText = styled(Text)`
  font-size: 1.4em;
  font-weight: normal;
`;

const ItemWrapper = styled.div`
  width: 100%;
  height: 50px;
  margin: 10px 0;
  background-color: ${props => props.selected ? '#35768f' : 'white'};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0;
  cursor: pointer;
  border: ${props => props.selected ? '4px solid #016990' : 'none'};

  &:hover {
    background-color: #35768f;
    border: 4px solid #016990;
  }

  &:hover p {
    color: white;
  }

  & p {
    color: ${props => props.selected ? 'white' : 'black'};
  }
`;

const MapMenuItem = ({ children, onClick, selected }) => (
  <ItemWrapper onClick={onClick} selected={selected}>
    <CityText>{children}</CityText>
  </ItemWrapper>
);

export default MapMenuItem;
