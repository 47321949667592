import React from 'react';
import { Marker } from 'react-google-maps';
const flagImage = require('assets/map-flag.png');

const FlagMarkers = ({stores, onClick}) => {
  return stores.map((store, idx) => {
    return (
      <Marker
        animation={window.google.maps.Animation.DROP}
        key={store.storeName}
        position={{
          lat: store.coordinates.latitude,
          lng: store.coordinates.longitude,
        }}
        icon={{
          url: flagImage,
          size: new window.google.maps.Size(145, 138),
          anchor: new window.google.maps.Point(34, 82),
          scaledSize: new window.google.maps.Size(110, 105)
        }}
        onClick={() => { onClick(store) }}
      >
      </Marker>
    );
  });
}

export default FlagMarkers;
