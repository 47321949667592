import React from 'react';
import styled from 'styled-components';
import { customMedia } from 'style/style-utils';
import MapMenuItem from './MapMenuItem';
import MapControl from './MapControl';

const MapMenuWrapper = styled.div`
  width: 300px;
  max-height: 400px;
  padding: 10px 20px;
  margin-right: 50px;
  margin-bottom: 40px;
  background-color: rgba(255, 255, 255,0.3);;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  overflow: auto;

  ${customMedia(1022)`
    display: none;
  `}
`;

const MapMenu = ({ stores, selectedStore, onClick }) => {
  const menuItems = stores.map((store) => {
    const selected = selectedStore && (store.storeName === selectedStore.storeName);

    return (
      <MapMenuItem
        selected={selected}
        onClick={() => { onClick(store) }}
        key={store.storeName}
      >
        {store.storeName.toUpperCase()}
      </MapMenuItem>
    );
  });

  return (
    <MapControl position={window.google.maps.ControlPosition.RIGHT_BOTTOM}>
      <MapMenuWrapper>{menuItems}</MapMenuWrapper>
    </MapControl>
  );
}

export default MapMenu;
