import React, { Component } from 'react';
import IslandMap from './IslandMap';
import Prismic from 'prismic-javascript';
import { get, set, pipe, reduce } from 'lodash/fp';
import styled from 'styled-components';
import { media } from 'style/style-utils';

function formatStores(stores) {
  const flatObject = reduce((acc, value) => {
    const flattenOpeningTime = reduce((acc, value) => {
      return [...acc, get('text')(value)];
    })([]);

    const setAddress = set('address')(get('address[0].text')(value));
    const setCoordinates = set('coordinates')(get('geoip_coordinates')(value));
    const setOpeningTime = set('openingTime')(flattenOpeningTime(get('opening_time')(value)));
    const setPhone = set('phone')(get('phone')(value));
    const setStoreName = set('storeName')(get('store_name[0].text')(value));
    const setLink = set('link')(get('info_box_link.url')(value));

    const obj = pipe(
      setAddress,
      setCoordinates,
      setOpeningTime,
      setPhone,
      setStoreName,
      setLink
    )({});

    return [...acc, obj];
  })([]);

  return flatObject(stores);
}

const StyledContainerElement = styled.div`
  height: 600px;
  width: 100%;
  margin: auto;

  ${media.phone`
    height: 350px;
  `}
`;

class IslandMapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStore: null,
      stores: []
    };
    this.handleFlagOrMenuClick = this.handleFlagOrMenuClick.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    var { islandId } = this.props;
    
    Prismic
      .getApi('https://diamant.cdn.prismic.io/api/v2')
      .then(function(api) {      
        return api.query([
          Prismic.Predicates.at('document.type', 'store'),
          Prismic.Predicates.at('my.store.island', islandId)
        ]);
      })
      .then(this.handleResponse, this.handleError);
  }


  handleFlagOrMenuClick(store) {
    this.setState({ selectedStore: store });
  }

  handleMapClick(store) {
    this.setState({ selectedStore: null });
  }

  handleResponse(response) {
    const stores = response.results.map((o) => {
      const publicationDate = new Date(o.first_publication_date);
      const publicationDateString = `${publicationDate.getDate()}.${(publicationDate.getMonth() + 1)}.${publicationDate.getFullYear()}`
      return Object.assign({}, o.data, { publicationDateString: publicationDateString });
    });

    this.setState({ stores: formatStores(stores) });
  }

  handleError(err) {
    console.log("Something went wrong: ", err);
  }

  render() {
    const { brand, centerCoordinates, islandName } = this.props;
    const { selectedStore, stores } = this.state;
    const apiKey = 'AIzaSyDrbNAIpJ2QEMdaDtj4t4-EQmQc00prbEg';

    return(
      <IslandMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `600px` }} />}
        containerElement={<StyledContainerElement/>}
        mapElement={<div style={{ height: `100%` }} />}
        stores={stores}
        selectedStore={selectedStore}
        handleFlagOrMenuClick={this.handleFlagOrMenuClick}
        handleMapClick={this.handleMapClick}
        centerCoordinates={centerCoordinates}
        brand={brand}
        islandName={islandName}
      />
    );
  }
}

export default IslandMapContainer;
