import React from 'react';
import styled from 'styled-components';
import { H2 } from 'components/common/Heading';
import { media } from 'style/style-utils';
import FacebookPage from './FacebookPage';
import TypeForm from './TypeForm';

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  ${media.phone`
    flex-direction: column-reverse;
  `}
`;

const Heading = styled(H2)`
  margin: 0 0 40px 0;
  text-align: center;
  ${media.phone`
    font-size: 1em;
  `}
`;

const ContactSection = ({ facebookPageUrl, typeformUrl }) => {
  return (
    <div>
      <Heading as='h2'>Contact</Heading>
      <Content>
        <FacebookPage url={facebookPageUrl}/>
        <TypeForm url={typeformUrl}/>
      </Content>
    </div>
  );
}

export default ContactSection;
