import { Component } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { MAP } from 'react-google-maps/lib/constants';

class MapControl extends Component {

  static contextTypes = {
    [MAP]: PropTypes.object
  }

  constructor(props, context) {
    super(props);
    this.map = context[MAP];
    this.controlDiv = document.createElement('div');
    this.map.controls[this.props.position].push(this.controlDiv);
  }

  render() {
    return createPortal(this.props.children, this.controlDiv);
  }
}

export default MapControl;
