import React from 'react';
import { Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import styled from 'styled-components';
import { media, customMedia }  from 'style/style-utils';
import Text from 'components/common/Text';
import { H3 } from 'components/common/Heading';

const StyledSlide = styled(Slide)`
  display: inline-block;
  background-color: #fcbe11;
  padding-bottom: 32.2668% !important;

  ${customMedia(1146)`
    padding-bottom: 32.9% !important;
  `}

  ${media.phone`
    padding-bottom: 95.9% !important;
  `}
`;

const NewsImageDiv = styled.div`
  display: inline-block;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 63%;
  max-width: 650px;
  max-height: 340px;

  ${media.phone`
    width: 100%;
    height: 54.5%;
  `}
`;

const NewsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  float: right;
  padding: 15px;
  overflow: auto;
  width: 37%;
  height: 100%;

  ${media.phone`
    width: 100%;
    height: 45.5%;
  `}
`;

const NewsDate = styled(Text)`
  font-size: 0.6em
`;

const NewsTitle = styled(H3)`
  margin: 0 0;
`;

const NewsText = styled(Text)`
  font-size: 0.8em;
`;

const NewsCarouselSlide = ({ index, dateString, title, content, newsImageUrl }) => (
  <StyledSlide index={index}>
    <NewsImageDiv src={newsImageUrl} />
    <NewsContent>
      <NewsDate>Publié le {dateString}</NewsDate>
      <NewsTitle>{title}</NewsTitle>
      <NewsText>{content}</NewsText>
    </NewsContent>
  </StyledSlide>
);

export default NewsCarouselSlide;
