import React from 'react';
import styled, { css } from 'styled-components';
import Text from 'components/common/Text';
import { media } from 'style/style-utils';
import mapIconHours from 'assets/map-icon-hours.png';
import mapIconPin from 'assets/map-icon-pin.png';
import mapIconPhone from 'assets/map-icon-phone.png';
import chevronUp from 'assets/chevron-up.png';
import chevronDown from 'assets/chevron-down.png';
import iconStore from 'assets/icon-menu-magasins.png';
import closeMenuButtonIcon from 'assets/menu-button-close.png';

const TopBarTitle = styled(Text)`
  display: flex;
  font-size: 1.5em;
  font-weight: normal;
  color: white;
  margin: 0;
  padding: 10px 20px;
`;

const Wrapper = styled.div`
  width: 350px;
  margin: 0;
  padding: 0;
  background-color: white;
  overflow: hidden;
  cursor: default;
  z-index: 150;
  box-shadow: 0 0px 16px rgba(0,0,0,0.5);

  ${media.phone`
    width: 340px;
    height: 330px;
    overflow: auto;
  `}
`;

const TopBarWrapper = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: solid 3px #e7e7e7;
  background-color: rgb(92, 132, 242);
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 18px 30px 30px 30px;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 0;
`;

const Icon = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 20px;
`;

const ContentTextStyle = css`
  font-size: 1.3em;
  font-weight: 300;
  margin: 0;
`;

const ContentText = styled(Text)`
  ${ContentTextStyle}
`;

const ContentALink = styled(Text)`
  ${ContentTextStyle}
  text-decoration: none;
`.withComponent('a');

const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 15px;
  background-color: #fcbe11;
  font-family: "Open Sans", sans-serif;
  flex: 100%;
  border-radius: 8px;
  padding: 10px;
  font-size: 1.4em;
  vertical-align: center;
  align-items: center;
`;

const DropDownImage = styled.img`
  width: 15px;
  height: 15px;
  margin-top: 5px;
  margin-left: 5px;
  display: inline-block;
  cursor: pointer;
`

const CloseButton = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background-image: url(${closeMenuButtonIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 15px;
  width: 15px;
  margin: 10px 10px 0 0;
  cursor: pointer;

  ${media.phone`
    display: block;
  `};
`;

const StoreInfoOverlay = ({ store, brand, onTimeOpen, showOpeningTime, onClose }) => {
  const openingTimeMarkups = store.openingTime.map((timeString, idx) => {
    const trailingBreakLine = (store.openingTime.length - 1 === idx ? <br/> : null);
    return <span key={idx}><br/>{timeString}{trailingBreakLine}</span>;
  });

  const openinTimeString = store.openingTime.reduce((acc, value) => {
    return `${acc}, ${value}`
  }).substring(0, 20);

  return (
    <Wrapper>
      <div ref={ ref => ref && window.google.maps.OverlayView.preventMapHitsFrom(ref) }>
        <TopBarWrapper>
          <CloseButton onClick={onClose}/>
          <TopBarTitle>
            {`${brand} | ${store.storeName}`}
          </TopBarTitle>
        </TopBarWrapper>

        <ContentWrapper>

          <ContentBox>
            <Icon src={mapIconPin}/><ContentText>{store.address}</ContentText>
          </ContentBox>

          <ContentBox>
            <a href={`tel:${store.phone}`}>
              <Icon src={mapIconPhone}/>
            </a>
            <ContentALink href={`tel:${store.phone}`}>{store.phone}</ContentALink>
          </ContentBox>

          <ContentBox>
            <Icon src={mapIconHours}/><ContentText onClick={onTimeOpen}>
              Horaires d’ouverture : {(showOpeningTime ? null : <br/>)}{(showOpeningTime ? openingTimeMarkups : `${openinTimeString}...`)}
              <DropDownImage src={(showOpeningTime ? chevronUp : chevronDown)}/>
            </ContentText>
          </ContentBox>

          <Button onClick={() => { window.open(store.link); }} >
            <Icon src={iconStore}/> Découvrir le magasin
          </Button>

        </ContentWrapper>
      </div>
     </Wrapper>
  );
}


export default StoreInfoOverlay;
