import React, { Component } from 'react';
import styled from 'styled-components';
import { media, customMedia } from 'style/style-utils';

const Wrapper = styled.div`
  width: 70%;
  height: 500px;

  ${customMedia(1175)`
    width: 60%;
  `}

  ${customMedia(950)`
    width: 50%;
  `}

  ${media.phone`
    width: 100%;
    margin-bottom: 20px;
    height: 160px;
  `}
`;

class TypeForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    try {
      const { url } = this.props;
      const typeformEmbed = require('@typeform/embed');

      if (this.form) {
        typeformEmbed.makeWidget(this.form.current, url, {
          // hideHeaders: true,
          // hideFooter: true,
          opacity: 75,
          buttonText: "Contactez-nous",
          onSubmit: function () {
            console.log('Typeform successfully submitted')
          }
        });
      }
    }
    catch(e) {
      console.log(e);
    }
  }

  render() {
    return (
      <Wrapper>
        <div ref={this.form} style={{ width: '100%', height: '100%'}}></div>
      </Wrapper>
    );
  }
}

export default TypeForm;
