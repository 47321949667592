import React from 'react';
import styled from 'styled-components';
import { media, customMedia } from 'style/style-utils';
import FacebookPageIframe from './FacebookPageIframe';
import FacebookPageMobileIframe from './FacebookPageMobileIframe';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: center;

  ${customMedia(1175)`width: 40%;`}
  ${customMedia(950)`width: 50%;`}
  ${media.phone`width: 100%;`}
`;

const MobileWrapper = styled.div`
  display: none;

  ${media.phone`
    display: block;
  `}
`;

const FacebookPage = ({ url }) => {
  return (
    <Wrapper>
      <FacebookPageIframe url={url}/>
      <MobileWrapper>
        <FacebookPageMobileIframe url={url}/>
      </MobileWrapper>
    </Wrapper>
  );
}

export default FacebookPage;
