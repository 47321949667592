import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Prismic from 'prismic-javascript';
import { get } from 'lodash/fp';
import Layout from 'components/layouts/IslandLayout';
import Menu from 'components/island/Menu';
import { H1 } from 'components/common/Heading';
import IslandMapContainer from 'components/island/map/IslandMapContainer';
import Space from 'components/common/Space';
import NewsSection from 'components/island/NewsSection';
import ContactSection from 'components/island/ContactSection';
import CatalogueSection from 'components/island/CatalogueSection';
import styled from 'styled-components';
import { media } from 'style/style-utils';
import { prismicDate } from 'utils/prismic-helpers';
import SectionWrapper from 'components/common/SectionWrapper';

const Heading = styled(H1)`
  text-align: center;
  font-size: 1.6em;

  ${media.phone`
    display: none;
  `}
`;

class IslandTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = { news: [] };
    this.handleResponse = this.handleResponse.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    var { data } = this.props;
    const islandId = data.prismicIsland.prismicId;

    let nowDate = new Date();
    nowDate.setDate(nowDate.getDate() - 1);

    let nowYear = `${nowDate.getFullYear()}`;

    let nowMonth = `${nowDate.getMonth() + 1}`;
    nowMonth = (nowMonth.length === 1 ? `0${nowMonth}` : nowMonth);

    let nowDay = `${nowDate.getDate()}`;
    nowDay = (nowDay.length === 1 ? `0${nowDay}` : nowDay);

    const nowSringDate = `${nowYear}-${nowMonth}-${nowDay}`;

    Prismic
      .getApi('https://diamant.cdn.prismic.io/api/v2')
      .then(function(api) {
        return api.query([
          Prismic.Predicates.at('document.type', 'news'),
          Prismic.Predicates.at('my.news.island', islandId),
          Prismic.Predicates.dateAfter('my.news.news_range', nowSringDate),
        ],
        { orderings : '[document.first_publication_date desc]' })
      })
      .then(this.handleResponse, this.handleError);
  }

  handleResponse(response) {
    const news = response.results.map((o) => {
      const publicationDate = prismicDate(o.first_publication_date);
      const publicationDateString = `${publicationDate.getDate()}.${(publicationDate.getMonth() + 1)}.${publicationDate.getFullYear()}`
      return Object.assign({}, o.data, { publicationDateString: publicationDateString });
    });

    this.setState({ news: news });
  }

  handleError(err) {
    console.log("Something went wrong: ", err);
  }

  render() {
    var { data } = this.props;
    var { news } = this.state;

    const islandData = get('prismicIsland.data')(data);

    const brand = get('brand.text')(islandData);
    const islandName = get('island_name.text')(islandData);
    const facebookPageUrl = get('facebook_page_url.url')(islandData);
    const catalogueUrl = get('catalogue_url.url')(islandData);
    const typeformUrl = get('typeform_url.url')(islandData);
    const centerCoordinates = get('center_coordinates')(islandData);
    const logoImage = get('logo')(islandData);

    const seoTitle = get('seo_meta_title.text')(islandData);
    const seoDescription = get('seo_meta_description.text')(islandData);
    const seoKeywords = get('seo_meta_keywords.text')(islandData);
    const islandId = get('prismicIsland.prismicId')(data)

    const footerText = get('prismicFooter.data.text.text')(data);
    const footerLegal = get('prismicFooter.data.legal.html')(data);

    return (
      <Layout
        footerText={footerText}
        footerLegal={footerLegal}
        seoTitle={seoTitle}
        seoDescription={seoDescription}
        seoKeywords={seoKeywords}
        logoImage={logoImage}
        isIslandPage={true}
        facebookPageUrl={facebookPageUrl}
      >
        <Space space={15} phone={0}/>
        <Heading as='h1' center>{islandName} - {brand.toUpperCase()}</Heading>
        <Space space={15} phone={0}/>
        <Menu areThereAnyNews={!(news.length === 0)}/>

        <SectionWrapper>
          <span id="magasins"></span>
          <IslandMapContainer
            islandName={islandName}
            brand={brand}
            centerCoordinates={centerCoordinates}
            islandId={islandId}
          />
          <Space space={40} />

          <span id="actualites"></span>
          <NewsSection news={news}/>
          <Space space={40} />

          <span id="catalogue"></span>
          <CatalogueSection url={catalogueUrl}/>
          <Space space={40} />

          <span id="contact"></span>
          <ContactSection facebookPageUrl={facebookPageUrl} typeformUrl={typeformUrl}/>
          <Space space={40} />
        </SectionWrapper>
      </Layout>
    );
  }
}

export const query = graphql`
  query ($uid: String) {
    prismicFooter {
      data {
        legal {
          html
        }
        text {
          text
        }
      }
    }
    prismicIsland(uid: { eq: $uid }) {
      id
      uid
      prismicId
      data {
        seo_meta_title {
          text
        }
        seo_meta_description {
          text
        }
        seo_meta_keywords {
          text
        }
        center_coordinates {
          latitude
          longitude
        }
        facebook_page_url {
          url
        }
        catalogue_url {
          url
        }
        typeform_url {
          url
        }
        island_name {
          text
        }
        brand {
          text
        }
        logo {
          url
        }
        typeform_url {
          url
        }
      }
    }
  }
`;

export default IslandTemplate;
