import React from 'react';
import { H2 } from 'components/common/Heading';
import { media } from 'style/style-utils';
import Catalogue from './Catalogue';
import styled from 'styled-components';

const Heading = styled(H2)`
  margin: 0 0 40px 0;
  text-align: center;

  ${media.phone`
    font-size: 1em;
  `}
`;

const CatalogueSection = ({ url }) => {
  return (
    <div>
      <Heading as='h2'>Catalogue numérique</Heading>
      <div>
        <Catalogue url={url}/>
      </div>
    </div>
  );
}

export default CatalogueSection;
