import React from 'react';
import styled from 'styled-components';
import iconOpen from 'assets/open-icon.png';
import { media } from 'style/style-utils';

const StyledIframe = styled.iframe`
  width: 100%;
  height: 600px;
  border: none;

  ${media.phone`
    height: 301px;
  `}
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 15px;
  background-color: #fcbe11;
  font-family: ${props => props.theme.fonts.text}, sans-serif;
  flex: 100%;
  border-radius: 8px;
  padding: 10px;
  margin: 15px;
  vertical-align: center;
  align-items: center;

  ${media.phone`
    font-size: 0.8em;
  `}
`;

const Icon = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 10px;

  ${media.phone`
    height: 18px;
    width: 18px;
  `}
`;

const Catalogue = ({ url }) => {
  return(
    <>
      <StyledIframe title="catalogue" src={url}></StyledIframe>
      <Button onClick={() => { window.open(url) }}>
        <Icon src={iconOpen}/> Voir le catalogue en pleine page
      </Button>
    </>
  );
}

export default Catalogue;
