import React from 'react';
import styled from 'styled-components';
import { H2 } from 'components/common/Heading';
import Text from 'components/common/Text';
import { media }  from 'style/style-utils';
import NewsCarousel from './NewsCarousel';

const HeadingsWrapper = styled.div`
  padding: 0 10px;
`;

const Heading = styled(H2)`
  margin: 0;
  text-align: center;

  ${media.phone`
    font-size: 1em;
  `}
`;

const SubHeading = styled(Text)`
  text-align: center;
  margin: 0 0 40px 0;
  ${media.phone`
    font-size: 0.8em;
  `}
`;

const NewsSection = ({ news }) => {
  return (
    (news.length === 0 ? null :
      <div>
        <HeadingsWrapper>
          <Heading>Nos dernières actualités et bons plans</Heading>
          <SubHeading>Rendez-vous en magasin pour plus de précisions sur nos offres !</SubHeading>
        </HeadingsWrapper>
        <NewsCarousel news={news}/>
      </div>
    )
  );
}

export default NewsSection;
