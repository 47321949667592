import React from 'react';
import styled from 'styled-components';
import { media } from 'style/style-utils';

const StyledIframe = styled.iframe`
  width: 340px;
  height: 500px;
  border: none;
  overflow: hidden;
  margin: auto;

  ${media.phone`display: none;`}
`;

function createIframeUrl(url) {
  const tabs = 'timeline';
  const width = '340';
  const height = '500';
  const smallHeader = 'true';
  const adaptContainerWidth = 'true';
  const hideCover = 'true';
  const showFacepile = 'false';

  return `https://www.facebook.com/plugins/page.php?href=${url}&tabs=${tabs}&width=${width}&height=${height}&small_header=${smallHeader}&adapt_container_width=${adaptContainerWidth}&hide_cover=${hideCover}&show_facepile=${showFacepile}`;
}

const FacebookPageIframe = ({ url }) => {
  const facebookPageUrl = url
    .replace(new RegExp('/', 'g'), '%2F')
    .replace(new RegExp(':', 'g'), '%3A');

  return (
    <StyledIframe
      title="Facebook page"
      src={createIframeUrl(facebookPageUrl)}
      scrolling="no"
      frameBorder="0"
      allowtransparency="true"
      allow="encrypted-media"
    >
    </StyledIframe>
  );
}

export default FacebookPageIframe;
