import React, { Component } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  OverlayView,
} from 'react-google-maps';
import MapMenu from './MapMenu';
import MapTitle from './MapTitle';
import { MAP } from 'react-google-maps/lib/constants';
import PropTypes from 'prop-types';
import StoreInfoOverlay from './StoreInfoOverlay';
import responsiveSizes from 'style/responsive-sizes';
import FlagMarkers from './FlagMarkers';
const mapStyles = require('assets/google-maps-style.json');

const getPixelPositionOffset = (showOpeningTime, phone) => (width, height) => ({
  x: 50,
  y: -((height / 2) + 50 + (showOpeningTime === true && !phone ? 60 : 0)),
})

class IslandMap extends Component {
  static contextTypes = {
    [MAP]: PropTypes.object
  }

  constructor(props, context) {
    super(props);
    this.map = context[MAP];
    this.state = {
      showOpeningTime: false,
    }
    this.toggleOpeningTime = this.toggleOpeningTime.bind(this);
  }

  toggleOpeningTime() {
    this.setState({
      showOpeningTime: !this.state.showOpeningTime
    });
  }

  render() {
    const { showOpeningTime } = this.state;
    const map = this.map.getDiv() || { offsetWidth: 0 };
    const {
      stores,
      selectedStore,
      handleFlagOrMenuClick,
      handleMapClick,
      brand,
      centerCoordinates,
      islandName
    } = this.props;
    var center = null;
    var zoom = 10;
    var defaultCenter = {
      lat: centerCoordinates.latitude,
      lng: centerCoordinates.longitude,
    }

    if (map.offsetWidth <= responsiveSizes.phone) {
      zoom = 9;
    }

    if (!!selectedStore) {
      center = {
        lat: selectedStore.coordinates.latitude,
        lng: selectedStore.coordinates.longitude,
      }
      this.map.setCenter(center);
      if (map.offsetWidth >= 1024) {
        this.map.panBy(200, -140);
      }
      else {
        this.map.panBy(220, -50);
      }
    }
    else {
      this.map.setCenter(defaultCenter);
      if (map.offsetWidth >= 1024) {
        this.map.panBy(170, -25);
      }
    }

    return (
      <GoogleMap
        defaultZoom={zoom}
        defaultCenter={defaultCenter}
        onClick={handleMapClick}
        onProjectionChanged={() => {
          if (!selectedStore && map.offsetWidth >= 1024) {
            this.map.panBy(170, 0);
          }
        }}
        defaultOptions={{
          styles: mapStyles,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
        }}
      >

        <MapTitle
          numberOfStores={stores.length}
          islandName={islandName}
        />

        <MapMenu
          stores={stores}
          selectedStore={selectedStore}
          onClick={handleFlagOrMenuClick}
        />

        <FlagMarkers stores={stores} onClick={handleFlagOrMenuClick} />

        {
          !!selectedStore
          ? (
            <OverlayView
              position={center}
              mapPaneName={OverlayView.FLOAT_PANE}
              getPixelPositionOffset={getPixelPositionOffset(this.state.showOpeningTime, map.offsetWidth <= responsiveSizes.phone)}
            >
              <StoreInfoOverlay
                brand={brand}
                store={selectedStore}
                onTimeOpen={this.toggleOpeningTime}
                showOpeningTime={showOpeningTime}
                onClose={handleMapClick}
              />
            </OverlayView>
          )
          : null
        }
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(IslandMap));
