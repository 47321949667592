import GlobalStyle from 'style/global-styles';
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import theme from 'style/theme';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import favicon from 'assets/favicon-32.ico';

const IslandLayout = ({
    children,
    footerText,
    seoTitle,
    seoDescription,
    seoKeywords,
    logoImage,
    footerLegal,
    facebookPageUrl,
    isIslandPage
  }) => (
  <>
    <GlobalStyle/>
    <Helmet
      title={seoTitle}
      meta={[
        { name: 'description', content: seoDescription },
        { name: 'keywords', content: seoKeywords },
      ]}
      link={[
          { rel: 'shortcut icon', type: 'image/x-icon', href: `${favicon}` }
      ]}
    />
    <ThemeProvider theme={theme}>
      <>
        <Header logoImage={logoImage}/>
        {children}
        <Footer
          text={footerText}
          legal={footerLegal}
          isIslandPage={isIslandPage}
          facebookPageUrl={facebookPageUrl}
        />
      </>
    </ThemeProvider>
  </>
)

IslandLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IslandLayout;
