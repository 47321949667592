import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from 'style/style-utils';
import Text from 'components/common/Text';

import logo from 'assets/logo.png';
import menuButtonIcon from 'assets/menu-button.png';
import closeMenuButtonIcon from 'assets/menu-button-close.png';
import iconCart from 'assets/icon-cart.png';
import iconCatalogue from 'assets/icon-catalogue.png';
import iconHorn from 'assets/icon-horn.png';
import iconEnvelope from 'assets/icon-envelope.png';

const MenuPlaceholder = styled.div`
  height: 60px;

  ${media.phone`
    height: 0px;
  `}
`;

const MenuWrapper = styled.nav`
  z-index: 6;
  position: ${props => props.fixed === true ? 'fixed' : 'relative'};
  box-shadow: 0px 0px 10px 0px #b5b5b5;
  top: 0;
  width: 100vw;
  margin-left: -50vw;
  left: 50%;
  background-color: white;

  ${media.phone`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    margin-left: 0;
  `};
`;

const MobileMenuButton = styled.div`
  display: none;
  position: ${props => props.open ? 'fixed' : 'inherit'};
  top: 0;
  left: 0;
  background-image: url(${props => props.open ? closeMenuButtonIcon : menuButtonIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 30px;
  width: 30px;
  margin: 10px 0 0 10px;
  cursor: pointer;

  ${media.phone`
    display: block;
  `};
`;

const MenuContent = styled.div`
  min-height: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  max-width: 800px;
  margin: auto;

  ${media.phone`
    display: ${props => props.open ? 'flex' : 'none'};
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #fcbe11;
    font-size: 1.8em;
  `};
`;

const MenuItemWrapper = styled(Link)`
  padding: 10px;
  color:inherit;
  text-decoration: none;
`;

const MenuItemContent = styled.div`
  display: flex;
  flex-direction: raw;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;

const MenuIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const Logo = styled.img`
  width: 90px;
  ${media.phone`
    display: none;
  `};
`;

const MenuText = styled(Text)`
  margin: 0px;
  font-size: 1em;
`;

const MenuItemSeparator = styled.img`
  height: 15px;
  width: 2px;
  background-color: black;

  ${media.phone`
    height: 1px;
    width: 200px;
  `};
`;

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixed: false,
      open: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }

  toggleMenu() {
    this.setState({ open: !this.state.open });
  }

  onScroll(e) {
    const fixed = this.state.fixed;

    const shouldUpdate = (
      (fixed === false && window.pageYOffset >= 246) ||
      (fixed === true && window.pageYOffset < 246)
    );

    if (shouldUpdate) {
      window.requestAnimationFrame(() => {
        this.setState({
          fixed: (window.pageYOffset >= 246)
        });
      });
    }
  }

  componentDidMount() {
    const node = this.trackedComponent;

    if (node) {
      window.addEventListener('scroll', this.onScroll);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  render() {
    const { fixed, open } = this.state;
    const { areThereAnyNews } = this.props;

    const menuItems = [
      { title: 'MAGASINS', iconUrl: iconCart, link: '#magasins'},
      { title: 'ACTUALITÉS', iconUrl: iconHorn, link: '#actualites'},
      { title: 'CONTACT', iconUrl: iconEnvelope, link: '#contact'},
      { title: 'CATALOGUE', iconUrl: iconCatalogue, link: '#catalogue'},
    ];

    if (areThereAnyNews === false) {
      delete menuItems[1];
    }

    const logoMarkup = <Link to={'/'}><Logo src={logo}/></Link>

    const menuItemsMarkup = menuItems.map((menuItem, idx) => {
      return (
        <React.Fragment key={menuItem.title}>
          <MenuItemWrapper
            innerRef={component => (this.trackedComponent = component)}
            to={menuItem.link}
            onClick={this.toggleMenu}
          >
            <MenuItemContent>
              <MenuIcon src={menuItem.iconUrl} />
              <MenuText>{menuItem.title}</MenuText>
            </MenuItemContent>
          </MenuItemWrapper>
          {idx < menuItems.length - 1 ? <MenuItemSeparator/> : null}
        </React.Fragment>
      );
    });

    return(
      <MenuPlaceholder>
      <MenuWrapper
        fixed={fixed}
      >
        <MobileMenuButton open={open} onClick={this.toggleMenu}/>
        <MenuContent open={open} >
          <MobileMenuButton open={open} onClick={this.toggleMenu}/>
          {fixed ? logoMarkup : null}
          {menuItemsMarkup}
        </MenuContent>
      </MenuWrapper>
    </MenuPlaceholder>
    );
  }
}

export default Menu;
