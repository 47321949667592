import React, { Component } from 'react';
import { CarouselProvider, Slider, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import { get } from 'lodash/fp';
import 'pure-react-carousel/dist/react-carousel.es.css';
import styled, { css } from 'styled-components';
import { media }  from 'style/style-utils';
import leftArrowImage from 'assets/left-arrow.png';
import rightArrowImage from 'assets/right-arrow.png';
import NewsCarouselSlide from './NewsCarouselSlide.js';

const StyledCarouselContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

const StyledSlider = styled(Slider)`
  width: 90%;

  ${media.tablet`
    width: 100%;
  `}
`;

const ButtonStyle = css`
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  background: transparent;
  width: 5%;

  ${media.tablet`
    display: none;
  `}
`;

const StyledButtonBack = styled(ButtonBack)`
  ${ButtonStyle};
`;

const StyledButtonNext= styled(ButtonNext)`
  ${ButtonStyle};
`;

const ButtonImage = styled.div`
  background-image: url(${props => ( props.right ? rightArrowImage : leftArrowImage )});
  float: ${props => (props.right ? 'right' : 'left')};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 0;
  padding: 0;
  width: 35px;
  height: 35px;
  border-radius: none;
  border-width: 0;
`;

const StyledDotGroup = styled(DotGroup)`
  text-align: center;
  margin-top: 20px;

  ${media.phone`
    display: none;
  `}

  & > button {
    background-color: #cccccc;
    width: 15px;
    height: 15px;
    border: none;
    border-radius: 8px;
    margin: 0px 4px;
  }

  & > button:disabled {
    background-color: #fcbe11;
  }
`;

class NewsCarousel extends Component {
 render() {
   const { news } = this.props;
   const numberOfSlides = news.length;

   const slideMarkups = news.map((aNews, idx) => {
     return (
       <NewsCarouselSlide
         key={idx}
         index={idx}
         newsImageUrl={get('news_pic.url')(aNews)}
         dateString={aNews.publicationDateString}
         title={aNews.news_title[0].text}
         content={aNews.news_content[0].text}
       />
     );
   });

   return (
     <CarouselProvider
       naturalSlideWidth={1171}
       naturalSlideHeight={420}
       totalSlides={numberOfSlides}
     >
       <StyledCarouselContent>

         <StyledButtonBack><ButtonImage left/></StyledButtonBack>

         <StyledSlider>
           {slideMarkups}
         </StyledSlider>

         <StyledButtonNext><ButtonImage right/></StyledButtonNext>

       </StyledCarouselContent>

       <StyledDotGroup/>

     </CarouselProvider>
   );
 }
}

export default NewsCarousel;
