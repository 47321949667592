import React from 'react';
import styled from 'styled-components';
import { customMedia } from 'style/style-utils';
import MapControl from './MapControl';

const MapTitleWrapper = styled.div`
  padding: 5px 30px;
  margin-top: 30px;
  background-color: rgba(255, 255, 255,0.2);;
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  overflow: auto;
  color: white;
  font-family: ${props => props.theme.fonts.heading};
  font-size: 2.8em;
  font-weight: 400;
  text-shadow: rgb(49, 54, 56) 1px 1px 2px;

  ${customMedia(1022)`
    display: none;
  `}
`;

const MapTitle = ({ numberOfStores, islandName }) => {
  const islandNameUpperCased = islandName.toUpperCase();
  const plurial = numberOfStores > 1;
  let linkWord = 'EN';

  if (islandNameUpperCased === 'MAYOTTE')
    linkWord = 'À';
  if (islandNameUpperCased === 'RÉUNION')
    linkWord = 'À LA';


  return (
    numberOfStores > 0
    ? (
      <MapControl position={window.google.maps.ControlPosition.TOP_CENTER}>
        <MapTitleWrapper>
          {numberOfStores} MAGASIN{(plurial ? 'S' : '')} {linkWord} {islandName.toUpperCase()} !
        </MapTitleWrapper>
      </MapControl>
    )
    : null
  );
};

export default MapTitle;
